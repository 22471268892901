.SideBarRoot * {
  box-sizing: border-box;
}

.SideBarSection {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 28px;
}

.SideBarSection:first-of-type {
  /* padding-top: 45px; */
}

.SectionKey {
  color: rgba(255, 255, 255, 0.7);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
  padding-left: 45px;
}

.SideBarSection.Collapsed .SectionKey {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 24px;
}

.NavItem {
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding-left: 45px;
  border-radius: 0px 50px 50px 0px;
  height: 44px;
  cursor: pointer;
}

.SideBarSection.Collapsed .NavItem {
  padding-left: 30px;
  padding-right: 30px;
  justify-content: center;
}

.NavItem:hover {
  background: linear-gradient(0deg, rgba(27, 146, 10, 0.6) 0%, rgba(27, 146, 10, 0.6) 100%);
}

.NavItem.Selected {
  background: rgb(88, 202, 4);
  background: linear-gradient(270deg, rgba(88, 202, 4, 1) 0%, rgba(141, 219, 83, 1) 100%);
}

.NavItem .NavIcon svg {
  width: 23px;
  height: 23px;
  fill: #44d400;
}

.NavItem.Selected .NavIcon svg {
  fill: #084a4e;
}

.NavIcon {
  width: 23px;
  height: 23px;
}

.NavIcon img {
}

.NavLabel {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.SideBarSection.Collapsed .NavLabel {
  display: none;
}

.NavItem.Selected .NavLabel {
  color: #084a4e;
}

.AvailabilityCalendar * {
  padding: 0;
  margin: 0;
}

.AvailabilityCalendar {
  box-sizing: border-box;
  align-items: center;
  column-gap: 9px;
  height: 56px;
  border-radius: 8px;
  border: 2px solid #3acf01;
  background: #2b4d1d;
  margin: auto;
  padding: 16px;
  margin-bottom: 16px;
  cursor: pointer;
}

.SideBarSection.Collapsed .AvailabilityCalendar {
  padding: 15px;
}

.AvailabilityCalendar > div {
  display: flex;
}

.AvailabilityCalendar p {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.AvailabilityCalendar img {
  width: 25px;
  height: 25px;
}
