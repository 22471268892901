:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono", "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(from 180deg at 50% 50%, #16abff33 0deg, #0885ff33 55deg, #54d6ff33 120deg, #0071ff33 160deg, transparent 360deg);
  --secondary-glow: radial-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(#00000080, #00000040, #00000030, #00000020, #00000010, #00000010, #00000080);

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  /* box-shadow: inset 0 0 20px 20px #23232329; */
}

input:autofill {
  box-shadow: unset !important;
  background-color: transparent;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

input[type="date"] {
  color-scheme: dark;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(to bottom right, rgba(1, 65, 255, 0), rgba(1, 65, 255, 0), rgba(1, 65, 255, 0.3));

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(#ffffff80, #ffffff40, #ffffff30, #ffffff20, #ffffff10, #ffffff10, #ffffff80);

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  background: #1c2218;
}

a {
  color: inherit;
  text-decoration: none;
}

.MuiInputLabel-root.Mui-focused {
  color: rgba(255, 255, 255, 0.7) !important;
}

.MuiInputLabel-root {
  color: rgba(255, 255, 255, 0.7) !important ;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

::-webkit-scrollbar {
  display: none;
}

.MuiDataGrid-root *::-webkit-scrollbar {
  display: block;
  height: 12px;
  width: 12px;
  background: transparent;
}

.MuiDataGrid-root *::-webkit-scrollbar-thumb {
  background: #ffffff75;
  -webkit-border-radius: 1ex;
  /* -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75); */
}

.MuiDataGrid-root *::-webkit-scrollbar-corner {
  background: #000;
}

.MuiDataGrid-columnHeader,
.MuiDataGrid-cell:focus,
.MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.FlipCard {
  perspective: 500px;
  cursor: pointer;
  user-select: none;
}

.FlipContent {
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
}

.FlipCard .FlipContent.Selected {
  transform: rotateY(180deg);
  transition: transform 0.5s;
}

.FlipCardA,
.FlipCardB {
  position: absolute;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
  border-radius: 8px;
}

.FlipCardB {
  transform: rotateY(180deg);
}

.InfiTable .ImNotRequiredCellClassMeh div {
  opacity: 0.6;
}

.InfiTable .ImNotRequiredRowClassMeh.Mui-selected,
.InfiTable .ImNotRequiredRowClassMeh.Mui-selected:hover {
  background-color: rgba(27, 146, 10, 0.05);
}
