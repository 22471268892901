.AvailabilitySidePanel {
  height: max-content;
  border: 2px solid #58ca04;
  background: #272d24;
  padding: 64px 35px;
  scrollbar-width: thin;
}

.AvailabilitySidePanel * {
  box-sizing: border-box;
}

.AvailabilitySidePanel::-webkit-scrollbar {
  width: 9px;
  display: unset;
}
.AvailabilitySidePanel::-webkit-scrollbar-track {
  background: transparent;
}
.AvailabilitySidePanel::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

.PropertyContainer {
  margin-bottom: 50px;
}

.PropertyContainer :global(.MuiSelect-select) {
  color: #fff;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.162px;
  padding: 12px 32px 12px 17px;
}

.PropertyHeader,
.ActionHeader {
  display: flex;
  align-items: center;
  column-gap: 13px;
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 22px;
}

.AvailabilityActions {
  margin-bottom: 30px;
}

.AvailabilityActionsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.AvailabilityFlex {
  display: flex;
  align-items: center;
}

.ActionHeader {
  margin-bottom: 0;
}

.AssignTask {
  display: flex;
  width: 129px;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #1b920a;
  color: #fff;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.AssignTask:hover {
  background: #1b920a;
}

.AssignTask:disabled {
  color: #fff;
  background: #6f6f6f;
}

.LegendList {
  display: flex;
  align-items: center;
  gap: 24px;
}

.LegendItem {
  display: flex;
  align-items: center;
  column-gap: 12px;
  color: rgba(255, 255, 255, 0.7);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Legend {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.LegendItem:nth-child(1) .Legend {
  border: 2px solid #206a14;
}

.LegendItem:nth-child(2) .Legend {
  background: rgba(27, 146, 10, 0.6);
}

.LegendItem:nth-child(3) .Legend {
  background: #3acf01;
}

.CalendarContainer {
  display: flex;
  flex-direction: column;
  row-gap: 37px;
  padding-top: 30px;
  border-radius: 6px;
  border: 1px solid #3acf01;
  background: #272d24;
}

.CalendarContainer :global(.MuiPickersCalendarHeader-label) {
  color: #fff;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.162px;
  margin: 0;
}

.CalendarContainer :global(.MuiDayCalendar-weekDayLabel) {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-family: Inter;
  font-size: 13.267px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.69px;
}

.CalendarContainer :global(.MuiPickersDay-root) {
  text-align: center;
  font-family: Inter;
  font-size: 13.267px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.69px;
}

.AvailabilityModal {
  max-width: 800px;
  max-height: 310px;
  width: 100%;
  height: 100%;
}

.AvailabilityModal :global(.MuiDialogContent-root) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ModalDesc {
  color: #fff;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.162px;
  text-align: center;
  margin-bottom: 30px;
}

.ButtonContainer {
  display: flex;
  justify-content: center;
  column-gap: 25px;
}

.SureBtn,
.CancelBtn {
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #fff;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  height: 49px;
}

.SureBtn,
.SureBtn:hover {
  width: 105px;
  background: #1b920a;
}

.CancelBtn,
.CancelBtn:hover {
  width: 135px;
  background: #2b4d1d;
}
